h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-family: Effra, sans-serif, Arial, sans-serif;
}

#modelo-de-declaracao-do-simples-nacional-para-nao-sofrer-retencoes-na-fonte {
    background-color: transparent;   
    color: #ffffff 
}

#planilhas-auxiliares {
    background-color: transparent; 
    color: #ffffff    
}

#planilha-simples-nacional {
    background-color: transparent;
    color: #ffffff     
}

.container-unico {
    margin: 1% 0 0 2%;
}

.btn-baixar-agora {
    border-radius: 50px;
}

.fa-dowload {
    margin: 1% 0 0 1%;
}