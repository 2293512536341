#notfound {
    background-color: transparent;
    color: #ffffff;
    text-align: center;
    padding: 50px;
}

.text-cinza {
    color: #6c757d;
    margin-top: 20px;
}

.btn-notfound {
    margin-top: 30px;
    border-radius: 50px;
}
  