h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    font-family: Effra, sans-serif, Arial, sans-serif, Alliance1;
    }
    
    .container-aviso-feriado {
    max-width: 600px;
    margin: 0 auto;
    background-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    }

    .container-aviso-feriado h1 {
      font-size: 42px;
    }
    
    .container-aviso-feriado img {
    width: 150px;
    }    
    
    .header-aviso-feriado {
    background-color: transparent;
    padding: 3%;
    color: #ffffff;
    }
    
    .segundo-h1 {
    color: #07fff0;
    }
    
    .content-aviso-feriado {
      margin-top: 1%; /* aumenta o espaçamento entre o header e o texto */
      padding: 20px; /* Adicione a quantidade de espaçamento desejada aqui */
      background-color: #ececec;
      border-radius: 21px; /* Ajuste o valor para arredondar as quinas */
    } 

    .content-aviso-feriado h5 {
        margin: 3% 0 1% 0;
    }    
    
    .content-aviso-feriado img {
    width: 24px;
    margin-right: 3%;
    }
       
    .btn-aviso-feriado {
    border-radius: 50px;
    margin: 9% 3% 9% 0;
    }
    
    footer.social-media {
      background-color: transparent;
      padding: 20px;
      text-align: center;
      color: #eeeaea;
      font-size: 14px;
      width: 100%;
      margin-top: auto; /* Empurra o footer para o fim da página */
  }
  
  .social-media p {
      color: #8d8d97;
      font-size: 9px;
      padding: 15px;
  }
  
  .social-media a {
      margin: 0 10px;
      color: #ffffff;
      font-size: 30px;
  }
  
  .social-media a:hover {
      color: #eeeeee;
  }
  
  /* Em telas menores */
  @media only screen and (max-width: 768px) {
      footer.social-media {
          position: relative; /* Mantém o footer no fluxo normal da página */
          margin-top: 6%;
      }
  }