#siga-nos {
  padding-top: 30%;
  text-align: center;
  color: #ffffff;
  background-color: #050922;
  position: relative; /* Garante que o conteúdo de #siga-nos fique na frente do canvas */
  z-index: 1; /* Define que o conteúdo fique à frente das partículas */
}

#siga-nos img {
  width: 60%;
  position: relative; /* Garante que o conteúdo de #siga-nos fique na frente do canvas */
  z-index: 1; /* Define que o conteúdo fique à frente das partículas */
}

.formtext-siga-nos p {
  color: #8d8d97;
  font-family: Effra, sans-serif;
  font-size: 6px;
  text-align: center;
  padding: 36% 0 3% 0;
  position: relative; /* Garante que o texto fique à frente do canvas */
  z-index: 1;
}

.siga-nos p {
  color: #07fff0;
  font-family: courier, arial, helvetica;
  font-size: 12px;
  text-align: center;
  padding: 0 0 0 0;
  position: relative; /* Mantém o parágrafo à frente do canvas */
  z-index: 1;
}

.siga {
  padding: 1% 6% 3% 6%;
  position: relative;
  z-index: 1;
}

.siga2 {
  padding: 0 6% 0 6%;
  position: relative;
  z-index: 1;
}

.btn-siganos {
  margin: 6% 0 6% 0;
  color: #ffffff;
  border-radius: 50px;
  min-width: 150px;
  position: relative; /* Garante que o botão fique à frente do canvas */
  z-index: 1;
}
