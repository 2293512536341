h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-family: Effra, sans-serif, Arial, sans-serif;
}

#certificacao-digital {
  padding: 3% 6% 8% 6%;
  text-align: center;
  background-color: #ffffff;
}

#certificacao-digital img {
  width: 60%;
}

.titulo {
  padding-top: 1%;
}

.etiquetas {
  padding: 3% 6% 8% 6%;
}

.btn-certificacao-digital {
  border-radius: 50px;
  margin: 3% 3% 0 0;
}

.h2-preco-certificado {
  font-size: 45px;
  font-family: Effra, sans-serif, Arial, sans-serif;
  font-weight: 300;
  color: #323338;
}

#afiliados {
  padding: 3% 6% 8% 6%;
  text-align: center;
  background-color: transparent;
  color: #ffffff;

}

#afiliados img {
  width: 60%;
}

.btn-afiliados {
  border-radius: 50px;
  margin: 3% 3% 3% 0;
}

#jh {
  padding: 3% 6% 8% 6%;
  text-align: center;
  background-color: #ffffff;
}

#jh img {
  width: 60%;
}

.btn-jh {
  border-radius: 50px;
  margin: 3% 3% 3% 0;
}

