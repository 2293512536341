.push-message-centro {
  position: fixed;
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Move o componente para o centro */
  padding: 10px;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  display: none;
  align-items: center;
  z-index: 9999; /* Defina um valor alto para ficar acima do conteúdo */
}

.push-message-canto-inferior {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  display: block;
  z-index: 9999; /* Defina um valor alto para ficar acima do conteúdo */
}

/* Defina uma cor de texto diferente */
.push-message p {
  color: #fff; /* Por exemplo, preto */
}

.push-message.show {
  display: flex;
  align-items: center;
  /* ... outros estilos ... */
}

.push-image-banner-1000x1000-servicos {
  max-width: 72%; /* Define a largura máxima da imagem */
  height: auto; /* Ajusta automaticamente a altura de acordo com a largura */
  display: block; /* Garante que a imagem respeite o espaço ocupado */
  margin: 0 auto; /* Centraliza horizontalmente a imagem */
  border-radius: 10px; /* Adicione o valor desejado para arredondar as bordas */
}

.push-image-push-comprar-ou-renovar-serasa {
  max-width: 100%; /* Define a largura máxima da imagem */
  height: auto; /* Ajusta automaticamente a altura de acordo com a largura */
  display: block; /* Garante que a imagem respeite o espaço ocupado */
  margin: 0 auto; /* Centraliza horizontalmente a imagem */
  border-radius: 10px; /* Adicione o valor desejado para arredondar as bordas */
}