h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-family: Effra, sans-serif, Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

#descadastrese {
  padding: 30px 0 102px 0;
  background-color: #ffffff;
}

#descadastrese img {
  width: 100%;
}

#descadastramento {
  padding: 3px 0 3px 0;
  text-align: center;
  background-color: #ffffff;
}

#descadastramento img {
  width: 60%;
}

.btn-descadrastre-se {
  border-radius: 50px;
  margin: 3% 3% 0 0;
}

.btn-descadastramento {
  border-radius: 50px;
}

.wrapper {
  padding: 30px;
}

.form-group {
  margin: 12px;
  display: flex;
  align-items: center;
}

.form-group label {
  cursor: pointer;
  padding-left: 12px;
}

.radio {
  cursor: pointer;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  outline: none;
  border: 3px solid #0d6efd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio:before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  background: #0d6efd;
  border-radius: 50%;
  opacity: 0;
  transition: all 300ms ease-in-out;
}

.radio:checked:before {
  opacity: 1;
}

.radio:focus {
  box-shadow: 0 0 5px #0d6efd;
}

.form {
  width: 100%;
  max-width: 32rem;
  font-size: 1.125rem;
}

.form label,
.form input {
  display: block;
  width: 100%;
}

.form label {
  line-height: 1;
  margin-bottom: 0.5rem;
}

.inputs {
  border-radius: 50px;
}
